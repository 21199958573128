// 个人中心 // 消息通知
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>
    <div v-show="$store.state.islogin">
      <div class="history_body">
        <!-- 左侧 -->
        <Leftside></Leftside>
        <transition name="RightToleft" v-if="$store.state.islogin">
          <!-- 右侧主题 -->
          <div class="his_r_box" v-show="enter">
            <div class="history_body_right">
              <div class="his_r_title"><span>消息&通知</span></div>
              <!-- 主体 -->
              <div class="his_r_body">
                <None v-if="!$store.state.message.message_list"></None>
                <div class="rech">
                  <div
                    class="rech_list mess_rech_list"
                    v-for="(item, index) in $store.state.message.message_list"
                    :key="index"
                    @click="openmess(index, item.id)"
                  >
                    <div class="mess_red" v-show="!item.read_time"></div>
                    <div class="mess_news">
                      <img :src="newShow[item.type]" alt="消息通知的类型图标" />
                    </div>
                    <div class="mess_list">
                      <div v-if="item">
                        <a>{{ item.title }}</a>
                        <span>{{ YS.toTime(item.create_time) }}</span>
                      </div>
                      <div class="mess_list_pdiv" v-if="item.content">
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div class="mess_img">
                      <img src="../assets/img/right.png" alt="打开消息通知图标" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="pagination" v-show="fy.pageEnter && fy.pagenum > 1">
              <div class="pagebody">
                <div class="page_left" @click="YS.pagebutton('left', fy, getmesslist)">
                  <img src="../assets/img/left.png" alt="上一页" />
                </div>
                <div class="page_cent">
                  <div
                    class="page_num"
                    v-for="(item, index) in fy.pageList"
                    :key="index"
                    @click="YS.clickPage(item, fy, getmesslist)"
                    :class="{ 'page_num-act': fy.pages.page == item }"
                  >
                    <a>{{ item }}</a>
                  </div>
                </div>

                <div class="page_right" @click="YS.pagebutton('right', fy, getmesslist)">
                  <img src="../assets/img/right.png" alt="下一页" />
                </div>
                <div class="page_total">
                  <input
                    class="fenye_input"
                    v-model="fy.jump"
                    @focus="YS.getInputFocus($event)"
                    @keyup.enter="YS.clickPage(fy.jump, fy, getmesslist)"
                  />
                </div>
                <div class="page_total">
                  <span>共{{ fy.pagenum }}页</span>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <transition name="show">
        <!-- 消息详情弹窗 -->
        <div class="mess_res_win" v-if="$store.state.showlogin.messReg">
          <div class="mess_body" v-if="$store.state.message.message_list[nowindex]">
            <a>
              {{ $store.state.message.message_list[nowindex].title }}
            </a>
            <span>{{ YS.toTime($store.state.message.message_list[nowindex].create_time) }}</span>
            <p>
              {{ $store.state.message.message_list[nowindex].content }}
            </p>
          </div>
          <img
            class="login-close"
            @click="YS.gotoVip($event, 'close', 'messReg')"
            src="../assets/img/close.png"
            alt="关闭消息详情弹窗"
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
let that;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
import None from '../components/None'; //无内容
export default {
  components: {
    Top,
    Leftside,
    None,
  },
  name: 'Message',
  data() {
    return {
      nowindex: null,
      nowReadtime: null,
      enter: false,
      havedata: false, //控制 显示没有任何内容 组件展示};
      newShow: {
        0: require('../assets/img/news1.png'),
        1: require('../assets/img/news2.png'),
      },
      //分页用
      fy: {
        pageEnter: false,
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 7,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
    };
  },
  created() {
    that = this;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.MOBILE_URL_BO
          : window.g.ub_index == 3
          ? window.g.MOBILE_URL_U
          : window.g.MOBILE_URL + '/mannoun.html';
    }
  },
  mounted() {
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    setTimeout(() => {
      that.YS.actlist(that.$store.state.leftListcss, 1, 'history_lc_list_act'); //添加默认选中样式
      that.YS.iconAct(that.$store.state.leftComponent, 1); //添加默认选中样式
    }, 0);
    // that.openmess();
    // 如果地址栏有参数传参时
    if (this.$route.query.index) {
      that.nowindex = this.$route.query.index;
      that.YS.gotoVip({}, 'open', 'messReg');
      that.api.user.api_messageread({ id: this.$route.query.id }).then((data) => {
        that.nowReadtime = data.message.read_time;
      });
    }

    that.getmesslist();
  },
  methods: {
    // 打开信息列表
    openmess(index, itemid) {
      that.nowindex = index;
      that.YS.gotoVip({}, 'open', 'messReg');

      if (!that.$store.state.message.message_list[that.nowindex].read_time) {
        that.api.user.api_messageread({ id: itemid }).then(() => {
          that.getmesslist();
        });
      }
    },
    // 获取消息列表
    getmesslist() {
      that.api.user.api_messagelist(that.fy.pages).then((data) => {
        that.$store.state.message.message_list = data.message_list;
        that.fy.pagetotal = data.message_total;
        // debugger;
        that.YS.jilu(that.fy);
        //当地址栏有参数时，此时进行到这已经打开完毕了，之后这里修改样式已读
        if (that.nowindex != null && that.nowReadtime) {
          that.$store.state.message.message_list[that.nowindex].read_time = that.nowReadtime;
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
