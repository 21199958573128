<template>
  <!-- 左侧主体 -->
  <div class="history_body_left">
    <!-- 左上角用户界面 -->
    <div class="user_info user_info_is">
      <!-- 头像 -->
      <div class="user_avatar">
        <!-- <img src="../assets/img/avatar-2-64.png" /> -->
        <!-- :style="{
            backgroundImage:
              'url(' +
              ($store.state.user.avatar
                ? $store.state.user.avatar
                : require('@/assets/img/avatar1-32.png')) +
              ')',
          }" -->
        <div class="ls_user">
          <img
            :src="$store.state.user.avatar ? $store.state.user.avatar : ''"
            :onerror="
              ub_index == 2
                ? $store.state.defaulava_bo
                : ub_index == 3
                ? $store.state.defaulava_u
                : $store.state.defaulava
            "
            height="80"
            width="80"
            alt="用户图标"
          />
        </div>
      </div>
      <!-- 用户昵称 -->
      <div class="user_name">
        <a>
          {{
            $store.state.user.nickname
              ? $store.state.user.nickname
              : $store.state.user.username
              ? $store.state.user.username
              : $store.state.user.email
              ? $store.state.user.email
              : '注册/登录'
          }}
        </a>
      </div>
      <!-- 用户提醒 -->
      <div class="user_remind">
        <!-- <a v-show="!isvip">开通VIP·看高清大片</a> -->
        <a v-show="isvip">目前您的身份为会员</a>
      </div>
      <!-- 立即开通按钮 -->
      <!-- v-show="
          $store.state.user.vip_expired_time < Date.parse(new Date()).toString().substr(0, 10)
        " -->
      <div class="user_govip">
        <a>您的身份为会员</a>
      </div>
      <div class="user_bottom">
        <!-- <span v-show="!isvip" class="left_isvip">目前您的身份为会员</span> -->
        <!-- <span class="resuvip" @click="getxinxi">重新获取会员信息</span> -->
      </div>
    </div>
    <!-- 收藏、求片、任务、充值 -->
    <div class="history_left_b">
      <div class="history_list" @click="YS.routetogo({ path: '/favorites' })">
        <img src="../assets/img/hisstory1.png" alt="收藏夹图标" />
        <a>收藏夹</a>
      </div>
      <div class="history_list" @click="cliqiupian">
        <img src="../assets/img/hisstory2.png" alt="求片图标" />
        <a>求片</a>
      </div>
      <div class="history_list" @click="cliHuiyuan">
        <img src="../assets/img/hisstory4.png" alt="充值图标" />
        <a>会员</a>
      </div>
    </div>
    <!-- 功能组件 -->
    <div class="history_lef-c">
      <div
        class="history_lc_list"
        v-for="(item, index) in $store.state.leftComponent"
        :key="index"
        @click="clickLeft($event, item.route)"
      >
        <img :src="item.imgnow" :alt="item.name" />
        <!-- <div></div> -->
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
let that;

export default {
  name: 'Leftside',
  data() {
    return {
      ub_index: 1,
    };
  },
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },
  },
  created() {
    that = this;
    that.istoken();
    that.$store.commit('getisvipCode');
    this.ub_index = window.g.ub_index;
  },
  beforeMount() {
    that.$store.commit('changeleftListcss', document.getElementsByClassName('history_lc_list'));
  },
  mounted() {
    document.getElementById('topNav').classList.add('top-nav-white');

    // that.$store.state.leftListcss = document.getElementsByClassName("history_lc_list"); //获取左侧列表的dom
    // debugger;
  },
  methods: {
    cliHuiyuan() {
      that.$parent.$refs.top.messTop = '您已是会员';
      that.$parent.$refs.top.mess_show = true;
      setTimeout(() => {
        that.$parent.$refs.top.mess_show = false;
      }, 3000);
    },
    clickLeft(e, routePath) {
      if (routePath) {
        that.YS.routetogo({ path: routePath });
      } else {
        that.YS.gotoVip({}, 'open', 'outlogin');
        // that.api.user.api_signout({});
        // that.$store.state.islogin = false;
        // localStorage.removeItem('user');
        // localStorage.removeItem('token');
        // alert('退出登录');
        // that.$store.commit('changelogin');
        // that.YS.routetogo({ path: '/' });
      }
    },
    //判断能否进入个人中心
    istoken() {
      if (that.$route.path != '/help' && that.$route.path != '/help.html') {
        var token = JSON.parse(localStorage.getItem('token'));
        if (!token || token.expired_time < Date.parse(new Date()).toString().substr(0, 10)) {
          that.YS.routetogo({ path: '/home' });
        }
      }
    },
    //点击求片
    cliqiupian() {
      //求片的id是4
      if (that.$route.path == '/opinion') {
        that.$parent.opi.category_id = 4;
      } else {
        that.YS.routetogo({ path: '/opinion', query: { id: 4 } });
      }
    },
    //点击开通vip
    leftTovip() {
      that.$parent.$refs.top.wantVIP();
    },
    //重新获取会员信息
    async getxinxi() {
      let data = await that.api.user.api_info({});
      var tem_local = JSON.parse(localStorage.getItem('user'));
      tem_local.vip_expired_time = data.user.vip_expired_time;
      localStorage.setItem('user', JSON.stringify(tem_local));
      localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
      that.$store.commit('changelogin');
      that.$store.commit('getisvipCode');
      that.$parent.$refs.top.usepubilDialog({ title: '更新信息成功！', code: true });
    },
  },
};
</script>
<style lang="scss"></style>
